import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { globalState } from '../../../shared/foreground/models';
import { getView } from '../../../shared/foreground/stateGetters';
import { useSavedFilteredViews } from '../../../shared/foreground/stateHooks';
import { usePinnedRssFoldersAndItems } from '../../../shared/foreground/stateHooks/rssFolders';
import { removeFeed } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { removeFilteredView } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import {
  setFeedIdToDelete,
  setFilteredViewIdToDelete,
} from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import useCurrentFilteredViewFromQuery from '../../../shared/foreground/useCurrentFilteredView';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import { allDefaultCategoriesQueries, getCategoriesIconMap } from '../../../shared/utils/filteredViews';
import { useIsNavigationSidebarHidden } from '../hooks/hooks';
import { useDragAndDropResponders } from '../hooks/useDragAndDropResponders';
import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import {
  expandOrCollapseAllNavItems,
  hideDesktopNavigationSidebar,
  setIsFeedCollapsed,
  setIsLibraryCollapsed,
  setIsPinnedViewsCollapsed,
} from '../stateUpdaters/sidebars';
import { isFilteredViewPinnedOnSidebar } from '../utils/isFilteredViewPinnedOnSidebar';
import { getFilterViewQueryFromPathname } from '../utils/pathnameHelpers';
import { useShortcutsMap } from '../utils/shortcuts';
import {
  droppableFolderPrefix,
  onDragEnd,
  pinnedFeedsDroppableId,
  pinnedViewsdroppableId,
} from '../utils/sidebar';
import useLocation from '../utils/useLocation';
import Button from './Button';
import { DeleteFeedDialog } from './DeleteFeedDialog';
import { DeleteViewDialog } from './DeleteViewDialog';
import AccountDropdown from './Dropdown/AccountDropdown';
import AddDropdown from './Dropdown/AddDropdown';
import StrokeToggleHideNavigationMenuIcon from './icons/20StrokeToggleHideNavigationMenuIcon';
import SolidTagIcon from './icons/24SolidTag';
import ArrowRightIcon from './icons/24StrokeArrowRight';
import ArticlesIcon from './icons/ArticlesIcon';
import BooksIcon from './icons/BooksIcon';
import EmailsIcon from './icons/EmailsIcon';
import FeedIcon from './icons/FeedIconSidebar';
import HomeIcon from './icons/HomeIcon';
import LibraryIcon from './icons/LibraryIcon';
import Logotype from './icons/Logotype';
import NavSettingsIcon from './icons/NavSettingsIcon';
import PDFIcon from './icons/PDFIcon';
import SearchNavIcon from './icons/SearchNavIcon';
import TwitterIcon from './icons/TwitterIcon';
import VideoIcon from './icons/VideoIcon';
import styles from './InboxSidebar.module.css';
import {
  DraggableRssFolderItem,
  FeedSectionItem,
  FilteredViewItem,
  NavItemSeparator,
  NavItemWithHover,
  PinnedRssItemsList,
  PinnedViewsItemsList,
} from './NavItem';
import navItemStyles from './NavItem.module.css';
import Tooltip from './Tooltip';

const Divider = ({ className = '' }: { className?: string }) => (
  <div className={`${styles.dividerWrapper} ${className}`}>
    <div className={styles.divider} />
  </div>
);

export const pathNamesWhereSidebarShouldAlwaysBeVisible = [
  '/profile',
  '/integrations',
  '/preferences',
  '/product-emails',
  '/add-to-library',
  '/add-to-feed',
  '/resources',
];

const itemIconMap = getCategoriesIconMap({
  articlesIcon: <ArticlesIcon key="article-icon" />,
  emailsIcon: <EmailsIcon key="email-icon" />,
  pdfsIcon: <PDFIcon key="pdf-icon" />,
  epubsIcon: <BooksIcon key="epub-icon" />,
  tweetsIcon: <TwitterIcon key="tweets-icon" />,
  videosIcon: <VideoIcon key="video-icon" />,
});

const InboxSidebar = React.memo(function InboxSidebar(): JSX.Element {
  const mainNavElRef = useRef<HTMLUListElement>(null);
  const { pathname } = useLocation();
  const [viewsScrolled, setViewsScrolled] = useState(false);
  const navigationSidebarIsHidden = useIsNavigationSidebarHidden();
  // We can't use useParams here because the sidebar is not a child of the FilterPage route
  const [filterQuery, setFilterQuery] = useState<string | undefined>(undefined);
  const savedView = useCurrentFilteredViewFromQuery(filterQuery ?? '');
  const allFilteredViews = useSavedFilteredViews();
  const categoryViews = allFilteredViews.filter(
    (view) => allDefaultCategoriesQueries.includes(view.query) && view.isUnpinned !== true,
  );
  const restOfPinnedViews = allFilteredViews.filter(isFilteredViewPinnedOnSidebar);
  const { rssFolders, pinnedRssItems } = usePinnedRssFoldersAndItems();
  const documentLocations = useDocumentLocations();
  const shortcutsMap = useShortcutsMap();
  const filteredViewIdToDelete = globalState(useCallback((state) => state.filteredViewIdToDelete, []));
  const feedIdToDelete = globalState(useCallback((state) => state.feedIdToDelete, []));
  const isLibraryCollapsed = globalState(
    useCallback((state) => state.client.navigationSidebar.isLibraryCollapsed, []),
  );
  const isFeedCollapsed = globalState(
    useCallback((state) => state.client.navigationSidebar.isFeedCollapsed, []),
  );
  const isPinnedViewsCollapsed = globalState(
    useCallback((state) => state.client.navigationSidebar.isPinnedViewsCollapsed, []),
  );
  const shouldRunSidebarItemCounts = globalState(
    useCallback((state) => state.shouldRunSidebarItemCounts, []),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ExpandOrCollapseAllNavItemsInSidebar],
    useCallback(() => {
      expandOrCollapseAllNavItems(!isLibraryCollapsed, { userInteraction: 'keypress' });
    }, [isLibraryCollapsed]),
    { description: 'Collapse/Expand all items in sidebar' },
  );

  useEffect(() => {
    setFilterQuery(getFilterViewQueryFromPathname(pathname));
  }, [pathname]);

  // Hack to prevent the container to reset it's scroll position after re-render
  useEffect(() => {
    const element = mainNavElRef.current;

    if (!element) {
      return;
    }

    if (window.pinnedViewsScrollTop) {
      element.scrollTop = window.pinnedViewsScrollTop;
    }
  }, [mainNavElRef]);

  useEffect(() => {
    const element = mainNavElRef.current;

    if (!element) {
      return;
    }

    const onScroll = () => {
      window.pinnedViewsScrollTop = element.scrollTop;
      setViewsScrolled(element.scrollTop > 0);
    };

    element.addEventListener('scroll', onScroll, { passive: true });

    return () => element.removeEventListener('scroll', onScroll);
  }, [mainNavElRef]);

  const onInboxDragEnd = useCallback(
    (result: DropResult) => {
      onDragEnd({ result, pinnedRssItems, restOfPinnedViews });
    },
    [restOfPinnedViews, pinnedRssItems],
  );

  useDragAndDropResponders({
    onDragEnd: onInboxDragEnd,
  });

  const [isOverlaying, setIsOverlaying] = useState(false);

  /*
    When overlaying the sidebar we need to first set the position to fixed and then show it.
    When removing the overlay of the sidebar we need to first hide it and then set the position back.
    This is to avoid weird flickerings.
  */
  const [isVisibleForOverlaying, setIsVisibleForOverlaying] = useState(false);

  const sidebarClassNames = useMemo(() => {
    const result = [styles.root];

    if (navigationSidebarIsHidden && !isVisibleForOverlaying) {
      result.push(styles.isHidden);
    } else {
      result.push(styles.isVisible);
    }

    if (isOverlaying) {
      result.push(styles.isOverlaying);
    }

    return result.join(' ');
  }, [isVisibleForOverlaying, navigationSidebarIsHidden, isOverlaying]);

  const cssTransitionDelay = 200;

  const showOverlay = () => {
    setIsOverlaying(true);
    setTimeout(() => {
      setIsVisibleForOverlaying(true);
    }, cssTransitionDelay);
  };

  const hideOverlay = (shouldDelay = true) => {
    if (window.isRadixDropdownOpen) {
      return;
    }

    setIsVisibleForOverlaying(false);
    setTimeout(
      () => {
        setIsOverlaying(false);
      },
      shouldDelay ? cssTransitionDelay : 0,
    );
  };

  const hideOrLockSidebar = () => {
    if (isOverlaying) {
      hideOverlay(false);
      hideDesktopNavigationSidebar(false);
    } else {
      hideDesktopNavigationSidebar(true);
    }
  };

  const onDeleteView = useCallback(() => {
    const filteredViewToDelete = getView(filteredViewIdToDelete);
    if (filteredViewIdToDelete && filteredViewToDelete) {
      removeFilteredView(filteredViewIdToDelete, {
        userInteraction: 'click',
        showToast: !filteredViewToDelete.rssFolderId,
      });
    }
    setFilteredViewIdToDelete(null);
  }, [filteredViewIdToDelete]);

  const onDeleteFeed = useCallback(() => {
    if (feedIdToDelete) {
      removeFeed(feedIdToDelete, { userInteraction: 'click' });
    }
    setFeedIdToDelete(null);
  }, [feedIdToDelete]);

  return (
    <>
      <DeleteViewDialog
        isOpen={Boolean(filteredViewIdToDelete)}
        onConfirm={onDeleteView}
        onCancel={() => setFilteredViewIdToDelete(null)}
      />

      <DeleteFeedDialog
        isOpen={Boolean(feedIdToDelete)}
        onConfirm={onDeleteFeed}
        onCancel={() => setFeedIdToDelete(null)}
      />

      {navigationSidebarIsHidden && !isOverlaying && (
        <div className={styles.overlayArea} onFocus={showOverlay} onMouseOver={showOverlay} />
      )}

      <div
        id="inbox-sidebar"
        className={sidebarClassNames}
        onMouseLeave={() => (isOverlaying ? hideOverlay(true) : undefined)}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <Link className={styles.logoWrapper} to="/">
              <Logotype />
            </Link>

            <Tooltip
              content={isOverlaying ? 'Lock panel open' : 'Hide left panel'}
              placement="right"
              shortcut={shortcutsMap[ShortcutId.HideLeftPanel]}
            >
              <Button
                variant="unstyled"
                className={styles.hideOrLockSidebarButton}
                onClick={hideOrLockSidebar}
              >
                <StrokeToggleHideNavigationMenuIcon
                  text={isOverlaying ? 'Lock panel open' : 'Hide left panel'}
                />
              </Button>
            </Tooltip>

            <AddDropdown triggerClassName={`${styles.addDropdownButton}`} />
          </div>

          <Divider className={viewsScrolled || window.pinnedViewsScrollTop ? styles.fullWidth : ''} />

          <nav className={styles.mainNav}>
            <ul ref={mainNavElRef}>
              <NavItemWithHover
                left={<HomeIcon />}
                name="Home"
                to="/home"
                isActive={pathname.startsWith('/home')}
                shortcut={shortcutsMap[ShortcutId.GoToHome]}
              />
              <NavItemWithHover
                left={<LibraryIcon />}
                name="Library"
                isActive={documentLocations.some((documentLocation) =>
                  pathname.startsWith(`/${documentLocation}`),
                )}
                shortcut={shortcutsMap[ShortcutId.GoToLibrary]}
                to="/library"
                isCollapsed={isLibraryCollapsed}
                setIsCollapsed={(isCollapsed) => setIsLibraryCollapsed(isCollapsed)}
              >
                {categoryViews.map((view) => {
                  const icon = itemIconMap[view.query];
                  const isActive = view.id === savedView?.id;

                  return (
                    <FilteredViewItem
                      key={view.id}
                      left={icon}
                      view={view}
                      isActive={isActive}
                      showGrab={false}
                      shouldRunSidebarItemCounts={shouldRunSidebarItemCounts && !isLibraryCollapsed}
                      type="built-in-category"
                    />
                  );
                })}
                <NavItemWithHover
                  left={<SolidTagIcon className={styles.tagIcon} />}
                  name="Tags"
                  isActive={pathname.startsWith('/tags')}
                  to="/tags"
                />
              </NavItemWithHover>
              <FeedSectionItem
                left={<FeedIcon />}
                isActive={
                  pathname.startsWith('/feed') &&
                  !pathname.startsWith('/feed/sources') &&
                  !pathname.startsWith('/feed/suggestions')
                }
                shortcut={shortcutsMap[ShortcutId.Feed]}
                isCollapsed={isFeedCollapsed}
                setIsCollapsed={(isCollapsed) => setIsFeedCollapsed(isCollapsed)}
              >
                {/*
                  Using 2 Droppable to prevent reordering effect :(
                  https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-863209754
                */}
                {rssFolders.map((rssFolder, index) => (
                  <Droppable
                    key={`folder-${rssFolder.id}`}
                    droppableId={`${droppableFolderPrefix}${rssFolder.id}`}
                    type="source-feeds"
                  >
                    {(dropProvided, dropSnapshot) => (
                      <div
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                        className={styles.pinnedRssFoldersWrapper}
                      >
                        <Droppable droppableId={`inner-${rssFolder.id}`} isDropDisabled>
                          {(dropProvided2) => {
                            const rssFolderFilteredView = allFilteredViews.find(
                              (view) => view.id === rssFolder.filteredViewId,
                            );
                            return (
                              <div {...dropProvided2.droppableProps} ref={dropProvided2.innerRef}>
                                <DraggableRssFolderItem
                                  rssFolder={rssFolder}
                                  rssFolderFilteredView={rssFolderFilteredView}
                                  isActive={savedView?.id === rssFolder.filteredViewId}
                                  itemIsBeingDraggedOver={dropSnapshot.isDraggingOver}
                                  shouldRunSidebarItemCounts={
                                    shouldRunSidebarItemCounts && !isFeedCollapsed
                                  }
                                  savedViewId={savedView?.id}
                                  pathname={pathname}
                                  index={index}
                                />
                              </div>
                            );
                          }}
                        </Droppable>
                        <span style={{ display: 'none' }}>{dropProvided.placeholder}</span>
                      </div>
                    )}
                  </Droppable>
                ))}
                <Droppable droppableId={pinnedFeedsDroppableId} type="source-feeds">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={navItemStyles.pinnedViewsWrapper}
                    >
                      <PinnedRssItemsList
                        pinnedRssItems={pinnedRssItems}
                        pathname={pathname}
                        shouldRunSidebarItemCounts={shouldRunSidebarItemCounts && !isFeedCollapsed}
                      />
                      {provided.placeholder}
                      <NavItemWithHover
                        left={<ArrowRightIcon className={styles.manageFeedsIcon} />}
                        name="Manage feeds"
                        nameClassName={styles.allFeedsLink}
                        isActive={
                          pathname.startsWith('/feed/sources') ||
                          pathname.startsWith('/feed/suggestions')
                        }
                        to="/feed/sources"
                      />
                    </div>
                  )}
                </Droppable>
              </FeedSectionItem>
              <NavItemSeparator
                name="Pinned"
                isCollapsed={isPinnedViewsCollapsed}
                setIsCollapsed={(isCollapsed) => setIsPinnedViewsCollapsed(isCollapsed)}
              >
                <Droppable droppableId={pinnedViewsdroppableId} type="pinned-views">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={navItemStyles.pinnedViewsWrapper}
                    >
                      <PinnedViewsItemsList
                        restOfPinnedViews={restOfPinnedViews}
                        savedViewId={savedView?.id}
                        shouldRunSidebarItemCounts={
                          shouldRunSidebarItemCounts && !isPinnedViewsCollapsed
                        }
                      />
                      {provided.placeholder}
                      <NavItemWithHover
                        left={<ArrowRightIcon className={styles.manageViewsIcon} />}
                        name="Manage views"
                        nameClassName={styles.allViewsLink}
                        isActive={pathname === '/views'}
                        to="/views"
                      />
                    </div>
                  )}
                </Droppable>
              </NavItemSeparator>
            </ul>
          </nav>

          <nav className={styles.bottomNav}>
            <ul>
              <Tooltip content="" shortcut={shortcutsMap[ShortcutId.Search]} placement="right">
                <NavItemWithHover
                  left={<SearchNavIcon className={styles.searchIcon} />}
                  nameClassName={styles.searchLink}
                  name="Search"
                  to="/search"
                  isActive={pathname.startsWith('/search')}
                />
              </Tooltip>
              <NavItemWithHover
                left={<NavSettingsIcon className={styles.settingsIcon} />}
                nameClassName={styles.settingsLink}
                name="Preferences"
                to="/preferences"
                isActive={pathname.startsWith('/preferences')}
              />
              <AccountDropdown
                triggerClassName={`${styles.appDropdownButton} ${styles.settingsButton}`}
              />
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
});

export default InboxSidebar;
